import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'state/hooks';

import { AppHeader } from 'features/app_header/AppHeader';
import './App.css';

import {
  checkUserStatus,
  kUserStatusLoggedIn,
  kUserStatusLoggedOut,
  kUserStatusPendingTerms,
  kUserStatusUnknown,
} from 'state/userSlice';

export function dispatchUserStatus(userStatus, dispatch, navigate) {
  if (userStatus === kUserStatusUnknown) {
    // if user status is unknown
    // ! TS2345: Argument of type 'AsyncThunkAction<any, void, AsyncThunkConfig>' is not assignable to parameter of type 'AnyAction'.
    // @ts-ignore
    dispatch(checkUserStatus());
  } else if (userStatus === kUserStatusLoggedIn) {
    // if user is logged in
    const searchParams = new URLSearchParams(window.location.search);
    if (!window.location.toString().includes('/app')) {
      navigate({
        pathname: '/app',
        search: searchParams.toString(),
      });
    }
  } else if (userStatus === kUserStatusLoggedOut) {
    // if user is logged out
    navigate({ pathname: '/login' });
  } else if (userStatus === kUserStatusPendingTerms) {
    navigate({ pathname: '/terms' });
  }
}

export function AppDispatcher() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userStatus = useAppSelector((state) => state.user.status);
  // const searchParams = new URLSearchParams(window.location.search).toString();
  console.log(`AppDispatcher - userStatus: ${userStatus}`);

  useEffect(() => {
    dispatchUserStatus(userStatus, dispatch, navigate);
  }, [dispatch, userStatus]);

  if (
    ![kUserStatusUnknown, kUserStatusLoggedIn, kUserStatusLoggedOut].includes(
      userStatus,
    )
  ) {
    return (
      <div className="App">
        <AppHeader />
        <div className="App-body">
          <div>
            userStatus:
            {userStatus}
            ...
          </div>
        </div>
      </div>
    );
  }
}
