import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import IconButton from '@mui/material/IconButton';
import { ExpandCircleDown } from '@mui/icons-material';

import { MAXIMIZE_DATA } from 'appConstants';

import { setMaximize, selectMaximize } from 'state/workflowSlice';

// https://mui.com/material-ui/material-icons/

// import styles from 'features/chart/Chart.module.css';

export function MaximizeButton() {
  const maximize = useSelector(selectMaximize);
  const dispatch = useDispatch();

  const rotate = maximize === MAXIMIZE_DATA ? 'rotate(0)' : 'rotate(180deg)';
  // console.log(`maximize: ${maximize} rotate: ${rotate}`);
  const handleMaximizeChartButtonClick = () =>
    dispatch(setMaximize(maximize ? null : MAXIMIZE_DATA));

  return (
    <IconButton
      color="primary"
      aria-label="expand chart"
      onClick={handleMaximizeChartButtonClick}
    >
      <ExpandCircleDown
        style={{ transform: rotate, transition: 'all 0.2s linear' }}
      />
    </IconButton>
  );
}
