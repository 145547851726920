import React, { useEffect, useMemo } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { displayTypicalMonth, sortTypicalMonths } from 'appUtils';
import { useGetTypicalMonthsQuery } from 'state/apiSlice';

import {
  setTypicalMonth,
  selectTypicalMonth,
  selectProject,
} from 'state/workflowSlice';
import LoadingSpinner from 'features/spinner/Spinner';

import styles from 'features/task_bar/Taskbar.module.css';
import { OverflowLabel } from './OverflowLabel';
import { TaskBarComponent } from './TaskBar';

export function GenericBaseMonthPicker({
  querier,
  fullWidth,
  value,
  setValue,
}: {
  querier: UseQuery<any>;
  fullWidth?: boolean;
  value: string;
  setValue: (v: string) => void;
}) {
  const project_slug = useSelector(selectProject);
  const {
    data: typicalMonthsInfo = [],
    isLoading,
    isSuccess,
    isFetching,
    isError,
  } = querier(project_slug, { skip: !project_slug });

  // memoize list of dates as strings
  const typicalMonthsList: Array<string> = useMemo(() => {
    if (typicalMonthsInfo) {
      return sortTypicalMonths(typicalMonthsInfo).map(
        (info) => info.typicalMonth,
      );
    } else {
      return [];
    }
  }, [typicalMonthsInfo]);

  const handleChange = (event) => {
    const newTypicalMonth = event.target.value;
    console.log(
      `TypicalMonthPicker handleChange newTypicalMonth: ${newTypicalMonth}`,
    );
    setValue(newTypicalMonth);
  };

  useEffect(() => {
    if (typicalMonthsList.length > 0) {
      if (!value || !typicalMonthsList.includes(value)) {
        console.log(
          'TypicalMonthPicker setting empty/unavailable typicalMonth to most recent month',
        );
        setValue(typicalMonthsList[typicalMonthsList.length - 1]);
      }
    }
  }, [typicalMonthsList, typicalMonthsInfo, setValue, value]);

  let content;

  if (isLoading || isFetching) {
    content = <LoadingSpinner />;
    content = 'loading typical days...';
  } else if (isSuccess) {
    content = (
      <Select
        className={
          fullWidth
            ? styles.task_bar_select_fullwidth
            : styles.task_bar_select_wide
        }
        value={value || ''}
        onChange={handleChange}
        size="small"
      >
        {typicalMonthsList.map((option) => (
          <MenuItem key={option} value={option}>
            {displayTypicalMonth(option)}
          </MenuItem>
        ))}
      </Select>
    );
  } else if (isError) {
    content = <span>Could not load dates.</span>;
  }
  return content;
}

export function BaseMonthPicker({
  querier,
  fullWidth = false,
}: {
  querier: UseQuery<any>;
  fullWidth?: boolean;
}) {
  const typicalMonth = useSelector(selectTypicalMonth);
  const dispatch = useDispatch();
  return (
    <GenericBaseMonthPicker
      querier={querier}
      fullWidth={fullWidth}
      value={typicalMonth}
      setValue={(v) => {
        dispatch(setTypicalMonth(v));
      }}
    />
  );
}

export function TypicalMonthPicker() {
  return (
    <TaskBarComponent>
      <OverflowLabel>
        Comparison:
        <BaseMonthPicker querier={useGetTypicalMonthsQuery} />
      </OverflowLabel>
    </TaskBarComponent>
  );
}
