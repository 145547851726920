import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Add, Check, Close, Delete, Edit, Search } from '@mui/icons-material';
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import TextField from '@mui/material/TextField';

import {
  SavedRoute,
  SavedRouteList,
  selectInSavedRouteEditMode,
  setDisplayedSavedRoute,
  setInSavedRouteEditMode,
  useGetSavedRoutesQuery,
  useSavedRouteCreateMutation,
  useSavedRouteDeleteMutation,
  useSavedRouteUpdateMutation,
} from 'state/savedRouteSlices';
import { updateNameError } from './util';

import {
  selectProject,
  selectRouteSelectionOrSegmentIdRoute,
  setRouteSelection,
  setSelectedSegmentId,
} from '../../state/workflowSlice';
import styles from './SavedRoutesToolbar.module.css';

export function SavedRoutesToolbar() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [editSavedRouteName, setEditSavedRouteName] = useState('');
  const [editSavedRouteId, setEditSavedRouteId] = useState(undefined);
  const [deleteSavedRouteId, setDeleteSavedRouteId] = useState(undefined);
  const [savedRoutes, setSavedRoutes] = useState<SavedRouteList>([]);
  const project_slug = useSelector(selectProject);
  const inSavedRouteEditMode = useSelector(selectInSavedRouteEditMode);
  const selectedRoute = useSelector(selectRouteSelectionOrSegmentIdRoute);

  const { currentData: savedRouteData } = useGetSavedRoutesQuery(project_slug, {
    skip: !project_slug,
  });
  const [updateSavedRoute, updateSavedRouteStatus] =
    useSavedRouteUpdateMutation();
  const [createSavedRoute, createSavedRouteStatus] =
    useSavedRouteCreateMutation();
  const [deleteSavedRoute, deleteSavedRouteStatus] =
    useSavedRouteDeleteMutation();

  useEffect(() => {
    let routesToDisplay = savedRouteData?.savedRoutes || [];

    if (search && showSearch) {
      routesToDisplay = routesToDisplay.filter((value) =>
        value.routeName.toLowerCase().includes(search.toLowerCase()),
      );
    }

    setSavedRoutes(routesToDisplay);
  }, [savedRouteData, search, showSearch]);

  const onEditClick = (event, index) => {
    const savedRoute = savedRoutes[index];
    dispatch(setRouteSelection(savedRoute.routeSegmentIds));
    dispatch(
      setSelectedSegmentId(
        savedRoute.routeSegmentIds[savedRoute.routeSegmentIds.length - 1],
      ),
    );
    dispatch(setInSavedRouteEditMode(true));
    setEditSavedRouteName(savedRoute.routeName);
    setEditSavedRouteId(savedRoute.id);
  };

  function exitEditMode() {
    dispatch(setInSavedRouteEditMode(false));
    setEditSavedRouteId(undefined);
    setEditSavedRouteName('');
  }

  const onEditSubmit = (event, index) => {
    const savedRoute = savedRoutes[index];
    console.assert(savedRoute.id === editSavedRouteId);
    updateSavedRoute({
      project_slug: project_slug as string,
      savedRouteId: editSavedRouteId,
      savedRoute: {
        routeName: editSavedRouteName,
        routeSegmentIds: selectedRoute,
      },
    });
    exitEditMode();
  };

  const onAddClick = (event) => {
    dispatch(setDisplayedSavedRoute(undefined));
    dispatch(setInSavedRouteEditMode(true));
    setEditSavedRouteName('');
    setEditSavedRouteId(-1);
  };

  const onAddSubmit = (event) => {
    dispatch(setInSavedRouteEditMode(false));
    if (editSavedRouteName) {
      createSavedRoute({
        project_slug: project_slug as string,
        savedRoute: {
          routeName: editSavedRouteName,
          routeSegmentIds: selectedRoute,
        },
      });
    }
    exitEditMode();
  };

  const onSelect = (event, index) => {
    exitEditMode();
    const route = savedRoutes[index].routeSegmentIds;
    dispatch(setRouteSelection(route));
    dispatch(setSelectedSegmentId(route[route.length - 1]));
  };

  const onDeleteClick = (id: string) => {
    setDeleteSavedRouteId(id);
    exitEditMode();
  };

  const onDeleteSubmit = (event, index) => {
    const savedRoute = savedRoutes[index];
    console.assert(
      savedRoute.id === deleteSavedRouteId,
      deleteSavedRouteId,
      savedRoute.id,
      savedRoute,
    );
    deleteSavedRoute({
      project_slug: project_slug as string,
      savedRouteId: deleteSavedRouteId,
    });
    setDeleteSavedRouteId(undefined);
  };

  function renderItem(savedRoute: SavedRoute, index) {
    const { routeName, id } = savedRoute;
    const isEditing = editSavedRouteId === id && inSavedRouteEditMode;
    const isDeleting = deleteSavedRouteId === id;
    let secondaryActions;
    let itemContent;

    if (!isEditing && !isDeleting) {
      // Normal savedRoute view
      secondaryActions = (
        <>
          <IconButton
            className={styles.bookmark_edit_button}
            edge="end"
            aria-label="edit"
            title="Edit"
            onClick={(event) => onEditClick(event, index)}
          >
            <Edit />
          </IconButton>
          <IconButton
            title="Delete"
            className={styles.bookmark_delete_button}
            onClick={(event) => onDeleteClick(id)}
          >
            <Delete />
          </IconButton>
        </>
      );
      itemContent = (
        <ListItemButton
          className={styles.bookmark_item_button}
          onClick={(event) => onSelect(event, index)}
        >
          <ListItemText primary={routeName} />
        </ListItemButton>
      );
    } else if (isDeleting) {
      secondaryActions = (
        <>
          <IconButton
            edge="end"
            aria-label="delete"
            title="Delete"
            color="error"
            onClick={(event) => onDeleteSubmit(event, index)}
          >
            <Check />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="cancel"
            title="Cancel"
            color="success"
            onClick={(event) => setDeleteSavedRouteId(undefined)}
          >
            <Close />
          </IconButton>
        </>
      );
      itemContent = (
        <ListItemText
          className={styles.confirm_delete_field}
          primary={`Delete '${routeName}'?`}
        />
      );
    } else {
      const editErrorString = updateNameError(
        routeName,
        editSavedRouteName,
        savedRoutes.map((e) => e.routeName),
      );
      // Editing view
      secondaryActions = (
        <>
          <IconButton
            edge="end"
            aria-label="submit edit"
            title="Submit edit"
            onClick={(event) => onEditSubmit(event, index)}
            disabled={!!editErrorString}
          >
            <Check />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="cancel"
            title="Cancel"
            onClick={(event) => exitEditMode()}
          >
            <Close />
          </IconButton>
        </>
      );
      itemContent = (
        <TextField
          label="Name"
          value={editSavedRouteName}
          onChange={(event) => setEditSavedRouteName(event.target.value)}
          variant="standard"
          size="small"
          className={styles.edit_name_field}
          error={!!editErrorString}
          helperText={editErrorString}
        />
      );
    }

    return (
      <ListItem
        disablePadding
        secondaryAction={secondaryActions}
        selected={id === editSavedRouteId}
        key={id}
      >
        {itemContent}
      </ListItem>
    );
  }

  return (
    <>
      <div className={styles.header_padded}>
        {!showSearch ? (
          <>
            <IconButton
              onClick={onAddClick}
              size="small"
              title="Create a route"
              disabled={!selectedRoute}
              className={styles.no_left_padding}
            >
              <Add />
            </IconButton>
            <IconButton
              onClick={(event) => setShowSearch(true)}
              size="small"
              title="Search routes"
            >
              <Search />
            </IconButton>
          </>
        ) : (
          <>
            <TextField
              label="Route name"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              variant="standard"
              size="small"
              className={styles.search_text_field}
            />
            <IconButton
              onClick={(event) => setShowSearch(false)}
              size="small"
              title="Hide search"
            >
              <Close />
            </IconButton>
          </>
        )}
      </div>
      {inSavedRouteEditMode && editSavedRouteId === -1 && (
        <div className={styles.header_padded}>
          <TextField
            label="New route name"
            value={editSavedRouteName}
            onChange={(event) => setEditSavedRouteName(event.target.value)}
            variant="standard"
            size="small"
            className={styles.search_text_field}
            error={
              !!updateNameError(
                undefined,
                editSavedRouteName,
                savedRoutes.map((e) => e.routeName),
              )
            }
            helperText={updateNameError(
              undefined,
              editSavedRouteName,
              savedRoutes.map((e) => e.routeName),
            )}
          />
          <IconButton
            onClick={onAddSubmit}
            disabled={
              !editSavedRouteName ||
              !!updateNameError(
                undefined,
                editSavedRouteName,
                savedRoutes.map((e) => e.routeName),
              )
            }
            size="small"
          >
            <Check />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="cancel"
            title="Cancel"
            onClick={(event) => {
              dispatch(setInSavedRouteEditMode(false));
            }}
          >
            <Close />
          </IconButton>
        </div>
      )}
      {savedRoutes && savedRoutes.length > 0 ? (
        <List className={styles.bookmark_toolbar_list}>
          {savedRoutes.map(renderItem)}
        </List>
      ) : (
        <div>
          <p className={styles.no_bookmarks_message}>No saved routes found</p>
          <p className={styles.no_bookmarks_message}>
            Select a route on the map and press + to add
          </p>
        </div>
      )}
    </>
  );
}
