import React from 'react';

import IconButton from '@mui/material/IconButton';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Tooltip from '@mui/material/Tooltip';

import styles from './InfoHover.module.css';

export function InfoHover({ children }: { children: string }) {
  return (
    <Tooltip describeChild title={children} className={styles.tooltip}>
      <IconButton className={styles.info_hover}>
        <QuestionMarkIcon />
      </IconButton>
    </Tooltip>
  );
}
