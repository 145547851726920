import { PlanningData } from 'appTypes';

import assign from 'lodash/assign';
import fromPairs from 'lodash/fromPairs';

import { BIN_15_MINS, BIN_60_MINS } from 'appConstants';
import {
  bin_size_to_minutes,
  MINUTES_PER_DAY,
  volume_data_map,
} from '../chart/PlanningChartDataExpander';

export type VolumeTableData = {
  Time: string;
  Saturday: string;
  Sunday: string;
  Weekday: string;
};

const zeroPad = (num, places) => String(num).padStart(places, '0');
const displayMinutes = (minutes) =>
  `${String(Math.floor(minutes / 60))}:${zeroPad(minutes % 60, 2)}`;

const debabelize = (babylonian: number) =>
  Math.floor(babylonian / 100) * 60 + (babylonian % 100);

export class PlanningTableDataExpander {
  binSize: number;
  firstBin: number;
  lastBin: number;
  numBins: number;

  constructor(binSize: string) {
    this.binSize = bin_size_to_minutes(binSize);
    this.numBins = MINUTES_PER_DAY / this.binSize;
    this.firstBin = 0;
    this.lastBin = this.firstBin + this.numBins;
    // console.log(`numBins ${this.numBins} firstBin ${this.firstBin} lastBin ${this.lastBin}`);
  }

  toIndex(bin) {
    const minutes = Math.floor(bin / 100) * 60 + (bin % 100);
    const index = minutes / this.binSize;
    return index;
  }

  displayBin(bin) {
    return `${displayMinutes(bin)} - ${displayMinutes(bin + this.binSize)}`;
  }

  expandData(rawData: PlanningData): Array<VolumeTableData> {
    // extract and backfill sparse SpeedData array with null datapoints for all time bins
    const data = Array(this.numBins);
    for (let i = 0; i < this.numBins; i += 1) {
      const b = this.firstBin + i * this.binSize;
      data[i] = { Time: this.displayBin(b) };
    }
    console.log(
      `PlanningTableDataExpander expandData empty data: ${JSON.stringify(data.slice(0, 10))}`,
    );

    if (rawData) {
      const sparseData = rawData.data.map(volume_data_map);
      // console.log(sparseData);
      for (let i = 0; i < sparseData.length; i += 1) {
        const { day, bin, y } = sparseData[i];
        const index = this.toIndex(bin);
        console.assert(index % 1 === 0, day, bin, y);
        assign(data[index], fromPairs([[day, Math.round(y)]]));
        console.log(`index: ${index} data: ${JSON.stringify(data[index])}`);
      }
    }
    console.log(
      `expandPlanningData data: ${JSON.stringify(data.slice(0, 10))}`,
    );

    return data;
  }
}
