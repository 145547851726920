import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { useGetDatesQuery } from '../../state/apiSlice';
import {
  addToVNRTData,
  replaceVNRTData,
  selectRepresentedSlots,
  selectVnrtData,
  useGetRealtimeDataQuery,
  useGetVNRTDataQuery,
} from '../../state/realtimeSlices';
import {
  selectProject,
  selectShortestAvailableBin,
  selectTargetDate,
} from '../../state/workflowSlice';
import { findDate } from '../task_bar/DatePicker';
import { ts_to_slots } from './slotsUtils';

dayjs.extend(utc);

export function getMinuteTicker() {
  return dayjs.utc().set('seconds', 0);
}

export function useRealtimeData(t) {
  const project = useSelector(selectProject);
  const targetDate = useSelector(selectTargetDate);
  const slotsRepresented = useSelector(selectRepresentedSlots);
  const prevVNRTData = useSelector(selectVnrtData);
  const binSize = useSelector(selectShortestAvailableBin);

  const { data: datesInfo } = useGetDatesQuery(project, { skip: !project });

  let day_info;
  if (targetDate && datesInfo) {
    day_info = findDate(targetDate, datesInfo);
  }

  const load_piecemeal = !day_info?.is_complete_vnrt; // If the day is not yet done, we'll stream load, else map load
  const skip_piecemeal =
    !project || !load_piecemeal || !(targetDate && datesInfo);
  const { currentData: realtimeData } = useGetRealtimeDataQuery(
    { project_slug: project, date: targetDate },
    { skip: skip_piecemeal, pollingInterval: 1000 * 60 }, // ms
  );
  const missing_slots = [];
  if (realtimeData && binSize) {
    const current_expected_slot =
      ts_to_slots(realtimeData.timestamp, binSize, targetDate) - 1;
    for (let i = 0; i <= current_expected_slot; i++) {
      if (!slotsRepresented[i] && !prevVNRTData[i]) {
        missing_slots.push(i);
      }
    }
  }

  const needs_more_data =
    !day_info?.is_complete_vnrt &&
    Object.keys(slotsRepresented).length > 0 &&
    missing_slots.length > 0;

  const { currentData: vnrtData } = useGetVNRTDataQuery(
    { project_slug: project, date: targetDate, slots: missing_slots },
    { skip: !needs_more_data },
  );

  return {
    realtime: realtimeData,
    vnrt: vnrtData,
    is_complete_vnrt: day_info?.is_complete_vnrt,
  };
}

export function RealtimeDataProvider() {
  const dispatch = useDispatch();
  const targetDate = useSelector(selectTargetDate);
  const { vnrt } = useRealtimeData(getMinuteTicker());
  const [fetchedDay, setFetchedDay] = useState(undefined);

  useEffect(() => {
    if (fetchedDay === targetDate) {
      if (vnrt) {
        dispatch(addToVNRTData(vnrt.data));
      }
    } else {
      console.log(
        'targetDate is different from previous fetched date, discarding old vnrt',
        targetDate,
        fetchedDay,
      );
      dispatch(replaceVNRTData(vnrt?.data || {}));
    }
    setFetchedDay(targetDate);
  }, [fetchedDay, dispatch, vnrt, targetDate]);

  return null;
}
