import React, { useContext } from 'react';

import { useSelector } from 'react-redux';

import FileSaver from 'file-saver';
import dayjs from 'dayjs';

import {
  selectTypicalMonth,
  selectSegmentId,
  selectCapacityThreshold,
  selectBinSize,
  selectCapacityLanes,
} from 'state/workflowSlice';
import { useGetSegmentInfoQuery } from 'state/apiSlice';

import { PlanningTableDataExpander } from 'features/table/PlanningTableDataExpander';

import { ExportButton } from 'features/task_bar/ExportButton';
import styles from 'features/task_bar/Taskbar.module.css';
import { usePlanningVolumesDataProvider } from './planningVolumesCommon';
import { LayerContext } from '../../state/LayerContext';

// https://www.highcharts.com/docs/export-module/client-side-export
// https://www.material-react-table.com/docs/examples/data-export

export function ExportVolumeButton() {
  const typicalMonth = useSelector(selectTypicalMonth);
  const segmentId = useSelector(selectSegmentId);
  const binSize = useSelector(selectBinSize);
  const capacityThreshold = useSelector(selectCapacityThreshold);
  const capacityLanes = useSelector(selectCapacityLanes);
  // cached so no need to memoize
  const { layer } = useContext(LayerContext);
  const { currentData: segmentData } = useGetSegmentInfoQuery(
    { layerId: layer, routeIds: [segmentId] },
    { skip: !layer || !segmentId },
  );

  const segment = segmentData && segmentData?.segments[segmentId];

  // typicalData from REST api
  const planningVolumeData = usePlanningVolumesDataProvider();

  const handleClick = (event) => {
    const dataExpander = new PlanningTableDataExpander(binSize);
    const data = dataExpander.expandData(planningVolumeData);
    const csv = [
      `Name, ${segment.properties.name}`,
      `ID, ${segmentId}`,
      segment.properties.cardinal_dir
        ? `Direction, ${segment.properties.cardinal_dir}`
        : null,
      `Data period, ${typicalMonth}`,
      `Access date, ${dayjs().format('YYYY-MM-DD')}`,
      segment.properties.cardinal_dir
        ? `Lane count, ${segment.properties.lane_count}`
        : null,
      `Lane capacity, ${capacityThreshold}`,
      `Total capacity, ${capacityThreshold * capacityLanes}`,
      '',
      '',
      'Time Start, Time End,Mon-Fri (hourly vehicle volume),Saturday (hourly vehicle volume),Sunday (hourly vehicle volume)',
    ];
    for (let i = 0; i < data.length; i += 1) {
      const row = data[i];
      const time = row.Time.split(' - ');
      csv.push(
        `${time[0]}, ${time[1]},${row.Weekday || ''},${row.Saturday || ''},${row.Sunday || ''}`,
      );
    }
    // console.log(`ExportVolumeButton segmentVolumes ${JSON.stringify(data)}`);
    const blob = new Blob([csv.filter((i) => i !== null).join('\r\n')], {
      type: 'text/plain;charset=utf-8',
    });
    FileSaver.saveAs(
      blob,
      `volume_${typicalMonth.replace('-', '_')}_${segmentId}.csv`,
    );
  };

  const isActive = !!planningVolumeData;

  return (
    <div className={styles.task_bar_component_bottom}>
      <ExportButton isActive={isActive} handleClick={handleClick} />
    </div>
  );
}
