import { CurrentUserResponse } from 'state/userSlice';

class WalkmeService {
  isLocal = window.location.hostname.includes('localhost');

  get walkmeVariables() {
    // @ts-ignore Property 'WALKME_VARIABLES' does not exist on type 'Window & typeof globalThis'.
    return window.WALKME_VARIABLES;
  }

  set walkmeVariables(value) {
    // @ts-ignore Property 'WALKME_VARIABLES' does not exist on type 'Window & typeof globalThis'.
    window.WALKME_VARIABLES = value;
  }

  resetWalkmeVariables() {
    if (!this.isLocal) {
      this.walkmeVariables = {};
    }
  }

  addWalkmeVariables(currentUser: CurrentUserResponse) {
    if (!this.isLocal) {
      this.walkmeVariables = {
        email: currentUser.email,
        user_id: currentUser.username,
        user_name: currentUser.email,
        actual_user_id: currentUser.username,
        effective_user_id: currentUser.username,
        actual_user_email: currentUser.email,
        effective_email_address: currentUser.email,
        is_emulating: false,
        url_subdomain: window.location.hostname.split('.')[0],
      };
    }
  }
}

export const walkmeService = new WalkmeService();
