import React from 'react';
import { useSelector } from 'react-redux';

import TableRow from '@mui/material/TableRow';
import { selectCurrentProjectInfo } from 'state/workflowSlice';

import { StyledCell } from './SegmentInfo';
import { useTravelTimeDataProvider } from '../workflow_traveltime/nrtTravelTimeCommon';
import { formatDistance } from '../common/utils';

export function RouteSegmentInfo() {
  const userProject = useSelector(selectCurrentProjectInfo);
  // ttData from REST api
  const { ttData } = useTravelTimeDataProvider();

  if (!ttData || !ttData?.routeInfo?.length || !userProject) {
    return null;
  } else {
    return (
      // <>
      <TableRow key="route_length">
        <StyledCell>Route Length</StyledCell>
        <StyledCell>
          {formatDistance(ttData.routeInfo.length, userProject.uses_metric)}
        </StyledCell>
      </TableRow>
      // </>
    );
  }
}
