export const updateNameError = (
  oldName: undefined | string,
  newName: string,
  existingNames: string[],
): string => {
  // Returns the error message, or undefined if no error

  if (oldName !== undefined && oldName === newName) {
    return undefined; // ok
  }

  if (existingNames.some((val) => newName === val)) {
    return 'Name must be unique';
  }

  return undefined;
};
