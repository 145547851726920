import Color from 'colorjs.io';
import { null_grey, transparent_null_grey } from '../../theme/cemTheme';
import { arrayEquals } from '../common/utils';

export function format_color_for_mapbox(col: Color) {
  if (col.alpha === 1) {
    const text = `rgb(${col.srgb.r * 255}, ${col.srgb.g * 255},${col.srgb.b * 255})`;
    return text;
  } else {
    const text = `rgba(${col.srgb.r * 255}, ${col.srgb.g * 255},${col.srgb.b * 255},${col.alpha})`;
    return text;
  }
}

export class ColorGradient {
  stop_colors: Array<{ stop: number; color: Color }>;
  constructor(stopsColors) {
    this.stop_colors = [];
    const colors = [];
    const stops = [];

    for (let i = 0; i < stopsColors.length; i++) {
      if (i % 2) {
        const new_col = new Color(stopsColors[i]);
        colors.push(new_col);
      } else {
        stops.push(stopsColors[i]);
      }
    }

    for (let i = 0; i < stops.length; i++) {
      this.stop_colors.push({ stop: stops[i], color: colors[i] });
    }

    // Stops are out of order, sort them
    if (!arrayEquals([...stops].sort(), stops)) {
      this.stop_colors = this.stop_colors.sort((a, b) => a.stop - b.stop);
    }
  }

  reverse() {
    this.stop_colors = this.stop_colors.reverse();
    return this;
  }

  toInterleaved() {
    const ret = [];
    for (let i = 0; i < this.stop_colors.length; i++) {
      ret.push(this.stop_colors[i].stop);
      const { color } = this.stop_colors[i];
      ret.push(format_color_for_mapbox(color));
    }
    return ret;
  }

  sample(value: number) {
    let sampling = -1;
    for (let i = 0; i < this.stop_colors.length; i++) {
      if (value <= this.stop_colors[i].stop) {
        sampling = i;
        break;
      }
    }
    if (sampling <= 0) {
      return this.stop_colors[0].color;
    } else if (sampling >= this.stop_colors.length - 1 || sampling === -1) {
      return this.stop_colors[this.stop_colors.length - 1].color;
    } else {
      const start = this.stop_colors[sampling - 1];
      const end = this.stop_colors[sampling];
      const relativeMix = (value - start.stop) / (end.stop - start.stop);
      console.assert(relativeMix > 0 && relativeMix <= 1);
      return start.color.range(end.color)(relativeMix);
    }
  }

  stops(): number[] {
    const ret: number[] = [];
    for (let i = 0; i < this.stop_colors.length; i++) {
      ret.push(this.stop_colors[i].stop);
    }
    return ret;
  }
}

export function viridis(lowStop, highStop) {
  const diff = (highStop - lowStop) / 4;
  return new ColorGradient([
    lowStop,
    'rgb(68, 1, 84)',
    lowStop + diff,
    'rgb(59, 82, 139)',
    lowStop + diff * 2,
    'rgb(33, 145, 140)',
    lowStop + diff * 3,
    'rgb(94, 201, 98)',
    highStop,
    'rgb(253, 231, 37)',
  ]).toInterleaved();
}

export function grey_yellow_red(greyStop, redStop) {
  const midStop = (greyStop + redStop) / 2;
  const yellow = 'rgb(207,183,98)';
  const red = 'rgb(255,4,4)';

  return new ColorGradient([
    greyStop,
    null_grey,
    midStop,
    yellow,
    redStop,
    red,
  ]);
}

export function timeline_bar_gradient(greyStop, redStop) {
  const midStop = (greyStop + redStop) / 3;
  const midStop2 = (2 * (greyStop + redStop)) / 3;
  const mid = 'rgb(180,151,231)';
  return new ColorGradient([
    greyStop,
    null_grey,
    midStop,
    null_grey,
    midStop2,
    mid,
    redStop,
    mid,
  ]);
}

export function bg_yellow_red(bgStop, redStop) {
  const midStop = (2.0 / 3) * bgStop + (1.0 / 3) * redStop;
  const bg = 'rgba(255,255,255,0.0)';
  const yellow = 'rgb(255,232,79)';
  const red = 'rgb(255,4,4)';
  return new ColorGradient([bgStop, bg, midStop, yellow, redStop, red]);
}

export function bg_yellow(bgStop, yellowStop) {
  const midStop = 1.0 * bgStop + 1.0 * yellowStop;
  const bg = 'rgba(255,255,255,0.0)';
  const yellow = 'rgb(207,183,98)';
  const half_yellow = 'rgb(207,183,98, 0.7)';
  return new ColorGradient([bgStop, bg, midStop, half_yellow, midStop, yellow]);
}

export const COLORMAP_TRAFFICLIGHT_0_1 = bg_yellow_red(
  1.0,
  0.0,
).toInterleaved();

export const COLORMAP_TRAFFICLIGHT_100_0 = bg_yellow_red(
  100,
  0,
).toInterleaved();

export const COLORMAP_VOLUME_M1_1 = new ColorGradient([
  -2,
  'rgb(255,4,4)',
  -1,
  'rgb(255,230,5)',
  -0.5,
  'rgb(202,204,85)',
  0.0,
  transparent_null_grey,
  0.5,
  'rgb(104,121,207)',
  1,
  'rgb(21,1,253)',
]).toInterleaved();

export const COLORMAP_MINUS_1_GREY = [-1, null_grey, -0.01, null_grey];
