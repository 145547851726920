// Import the RTK Query methods from the React-specific entry point
import { createSlice } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { restApiUrl } from "appConstants";
import { getCSRFCookie } from "axios_middleware";

// https://redux.js.org/tutorials/essentials/part-7-rtk-query-basics
// https://redux-toolkit.js.org/rtk-query/usage/prefetching

export type SavedRoute = {
  id: string;
  routeName: string;
  routeSegmentIds: string[];
};
export type SavedRouteList = SavedRoute[];

const transformResponseRoute = (
  rawResult: any,
  meta,
  arg
): { savedRoutes: SavedRouteList } => {
  return {
    savedRoutes: rawResult.saved_routes.map((serverRoute) => {
      return {
        id: serverRoute.id,
        routeName: serverRoute.name,
        routeSegmentIds: serverRoute.route_segment_ids,
      };
    }),
  };
};

export const savedRouteApiSlice = createApi({
  reducerPath: "savedRouteApi",
  baseQuery: fetchBaseQuery({
    baseUrl: restApiUrl,
    prepareHeaders: (headers) => {
      headers.append("X-CSRFToken", getCSRFCookie());
    },
  }),
  tagTypes: ["savedRoutes"],
  endpoints: (builder) => ({
    // @ts-ignore
    getSavedRoutes: builder.query<{ savedRoutes: SavedRouteList }, string>({
      query: (project_slug) => {
        return `${project_slug}/saved_routes/`;
      },
      transformResponse: transformResponseRoute,
      providesTags: [{ type: "savedRoutes" }],
    }),
    // @ts-ignore
    savedRouteUpdate: builder.mutation<
      { savedRoutes: SavedRouteList },
      {
        project_slug: string;
        savedRouteId: string;
        savedRoute: Partial<SavedRoute>;
      }
    >({
      query: ({ project_slug, savedRouteId, savedRoute }) => {
        return {
          url: `${project_slug}/saved_routes/${savedRouteId}/`,
          method: "POST",
          body: {
            id: savedRouteId,
            name: savedRoute.routeName,
            route_segment_ids: savedRoute.routeSegmentIds,
          },
        };
      },
      transformResponse: transformResponseRoute,
      invalidatesTags: [{ type: "savedRoutes" }],
    }),
    // @ts-ignore
    savedRouteDelete: builder.mutation<
      { savedRoutes: SavedRouteList },
      { project_slug: string; savedRouteId: string }
    >({
      query: ({ project_slug, savedRouteId }) => {
        return {
          url: `${project_slug}/saved_routes/${savedRouteId}/`,
          method: "DELETE",
        };
      },
      transformResponse: transformResponseRoute,
      invalidatesTags: [{ type: "savedRoutes" }],
    }),
    // @ts-ignore
    savedRouteCreate: builder.mutation<
      { savedRoutes: SavedRouteList },
      {
        project_slug: string;
        savedRoute: Partial<SavedRoute>;
      }
    >({
      query: ({ project_slug, savedRoute }) => {
        return {
          url: `${project_slug}/saved_routes/`,
          method: "PUT",
          body: {
            name: savedRoute.routeName,
            route_segment_ids: savedRoute.routeSegmentIds,
          },
        };
      },
      transformResponse: transformResponseRoute,
      invalidatesTags: [{ type: "savedRoutes" }],
    }),
  }),
});

export const {
  useGetSavedRoutesQuery,
  useSavedRouteUpdateMutation,
  useSavedRouteCreateMutation,
  useSavedRouteDeleteMutation,
} = savedRouteApiSlice;

const initialState: {
  displayedSavedRoute?: SavedRoute;
  inSavedRouteEditMode: boolean;
} = {
  displayedSavedRoute: undefined,
  inSavedRouteEditMode: false,
};

export const savedRouteSlice = createSlice({
  name: "savedRoutes",
  initialState,
  reducers: {
    setDisplayedSavedRoute: (state, action) => {
      state.displayedSavedRoute = action.payload;
    },
    setInSavedRouteEditMode: (state, action) => {
      state.inSavedRouteEditMode = action.payload;
    },
  },
});

const { actions, reducer } = savedRouteSlice;
export const { setDisplayedSavedRoute, setInSavedRouteEditMode } = actions;

// Selectors
export const getSavedRouteState = (rootState) => {
  return rootState[savedRouteSlice.name];
};

export const selectDisplayedSavedRoute = (rootState) => {
  return getSavedRouteState(rootState).displayedSavedRoute;
};

export const selectInSavedRouteEditMode = (rootState) => {
  return getSavedRouteState(rootState).inSavedRouteEditMode;
};
