import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { VIZ_MODE_CHART, VIZ_MODE_TABLE } from 'appConstants';

import { selectVizMode, setVizMode } from 'state/workflowSlice';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import styles from 'features/workflow_common/ChartTableSwitcher.module.css';

const kDefaultVizMode = VIZ_MODE_CHART;

function displayVizMode(vizMode: string) {
  if (vizMode === VIZ_MODE_CHART) {
    return 'Chart';
  } else if (vizMode === VIZ_MODE_TABLE) {
    return 'Table';
  } else return null;
}

function VizModeButton({
  vizMode,
  isCurrent,
  handleClick,
}: {
  vizMode: string;
  isCurrent: boolean;
  handleClick: any;
}) {
  return (
    <Button
      variant={isCurrent ? 'contained' : undefined}
      size="small"
      onClick={isCurrent ? null : handleClick}
      value={vizMode}
      className={styles.switch_button}
    >
      {displayVizMode(vizMode)}
    </Button>
  );
}

export function ChartTableSwitcher() {
  const vizMode = useSelector(selectVizMode);

  const dispatch = useDispatch();

  const handleClick = (event) => {
    // console.log('handleClick event', event.target.value);
    const newVizMode = event.target.value;
    // console.log(`ChartTableSwitcher handleClick newVizMode: ${newVizMode}`);
    dispatch(setVizMode(newVizMode));
  };

  useEffect(() => {
    if (!vizMode) {
      // console.log(`ChartTableSwitcher setting visualization to ${kDefaultVizMode}`);
      dispatch(setVizMode(kDefaultVizMode));
    }
  }, [dispatch, vizMode]);

  return (
    <div className={styles.switch_container}>
      <ButtonGroup
        fullWidth
        disableElevation
        aria-label="outlined primary button group"
      >
        <VizModeButton
          vizMode={VIZ_MODE_CHART}
          isCurrent={vizMode === VIZ_MODE_CHART}
          handleClick={handleClick}
        />
        <VizModeButton
          vizMode={VIZ_MODE_TABLE}
          isCurrent={vizMode === VIZ_MODE_TABLE}
          handleClick={handleClick}
        />
      </ButtonGroup>
    </div>
  );
}
