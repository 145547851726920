import React, { useMemo, useContext } from 'react';
import { useSelector } from 'react-redux';

import { LayerContext } from 'state/LayerContext';

import { selectSegmentId, selectTypicalMonth } from 'state/workflowSlice';
import { useGetSegmentInfoQuery } from 'state/apiSlice';

import { MaximizeButton } from 'features/workspace/MaximizeButton';
import { ChartTableSwitcher } from 'features/workflow_common/ChartTableSwitcher';
import { displayTypicalMonth } from 'appUtils';

import styles from 'features/workspace/Workspace.module.css';

// https://mui.com/material-ui/material-icons/

export function PlanningSegmentInfo() {
  const segmentId = useSelector(selectSegmentId);
  const typicalMonth = useSelector(selectTypicalMonth);

  // cached so no need to memoize
  const { layer } = useContext(LayerContext);
  const { currentData: segmentData } = useGetSegmentInfoQuery(
    { layerId: layer, routeIds: [segmentId] },
    { skip: !layer || !segmentId },
  );

  // momeoze streetName
  const streetName = useMemo(() => {
    const segmentProperties =
      segmentData && segmentData.segments[segmentId]?.properties;
    return (
      segmentProperties &&
      `${segmentProperties.name} ${segmentProperties.cardinal_dir}`
    );
  }, [segmentData, segmentId]);

  const periodName = displayTypicalMonth(typicalMonth);
  const description = `Typical Volume | ${streetName} | ${periodName}`;

  return <div>{description}</div>;
}

export function PlanningChartHeader() {
  return (
    <div className={styles.chart_header}>
      <div className={styles.chart_header_left}>
        <PlanningSegmentInfo />
      </div>

      <div className={styles.chart_header_right}>
        <ChartTableSwitcher />
        <MaximizeButton />
      </div>
    </div>
  );
}
