import React, { PropsWithChildren } from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { red } from '@mui/material/colors';

export const stl_gold_color = '#ffda6a';
export const stl_gold_color_light = '#fae299';
export const stl_blue_color = '#77b1d9'; // blue
export const stl_realtime_color = '#4d8335'; // green
export const stl_realtime_color_light = '#6bad61'; // lighter green, readable against b/w
export const stl_realtime_color_light_desat = '#769375'; // lighter green, readable against b/w
export const stl_background_color = '#252424';
export const stl_secondary_background_color = '#171717';
export const stl_hilited_background_color = '#686868';
export const stl_secondary_hilited_background_color = '#707070';
export const stl_hilited_text_color = '#fae299';
export const stl_light_background_color = '#888888';
export const stl_light_grey = '#BBBBBB';
export const white = '#FFFFFF';
export const black = '#000000';

export const primary_text_color = white;
export const secondary_text_color = '#B0B0B0';
export const null_grey = '#7d7d7d'; // From the map
export const transparent_null_grey = 'rgba(125,125,125,0)';

export const header_color = stl_background_color;
export const workspace_color = stl_background_color;
export const navbar_color = stl_secondary_background_color;
export const taskbar_color = stl_secondary_background_color;
export const chart_background_color = stl_secondary_background_color;

// https://mui.com/material-ui/customization/default-theme/
// https://mui.com/material-ui/customization/typography/

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontSize: 14,
  },
  palette: {
    mode: 'dark',
    primary: {
      main: stl_gold_color_light,
      contrastText: stl_secondary_background_color,
    },
    secondary: {
      main: stl_secondary_background_color,
      contrastText: stl_gold_color_light,
    },
    error: {
      main: red.A400,
    },
    text: {
      primary: primary_text_color,
      secondary: stl_gold_color,
    },
    background: {
      default: stl_background_color,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          height: '2rem',
          fontWeight: '500',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'var(--primary-text)',
        },
      },
    },
    // TextField padding
    MuiFilledInput: {
      styleOverrides: {
        input: {
          paddingTop: '6px',
          paddingBottom: '6px',
          fontSize: '1rem',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
            {
              display: 'none',
            },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1rem',
          backgroundColor: 'var(--background-col)',
          filter: 'drop-shadow(0px 0px 2px #FFFFFF30)',
        },
      },
    },
  },
});

export function CemThemeProvider({ children }: PropsWithChildren) {
  const vars = `
    :root {
      --background-col: ${stl_background_color};
      --header-color: ${header_color};
      --workspace-background-col: ${workspace_color};
      --navbar-background-color: ${navbar_color};
      --taskbar-background-col: ${taskbar_color};
      --chart_background_color: ${chart_background_color};
      --gold-col: ${stl_gold_color};
      --gold-col-light: ${stl_gold_color_light};
      --realtime-col: ${stl_realtime_color};
      --light-grey-divider: ${stl_secondary_hilited_background_color};
      --secondary-text: ${secondary_text_color};
      --primary-text: ${primary_text_color};
      --null-grey: ${null_grey};
    }
  `;
  return (
    <>
      <style>{vars}</style>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </>
  );
}

export default theme;
