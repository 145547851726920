import dayjs from 'dayjs';
import Highcharts from 'highcharts';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import {
  baseChartOptions,
  grid_color,
  label_color,
  axis_color,
  typical_color,
  recent_speed_color,
} from './chartOptions';
import { nrtXAxisOptions } from './nrtChartCommon';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

// highcharts-react npm page
// https://www.npmjs.com/package/highcharts-react-official

// highcharts-react github repo
// https://github.com/highcharts/highcharts-react#readme

// highcharts documentation
// https://api.highcharts.com/highcharts/

export const travelTimeChartOptions = {
  ...baseChartOptions,
  yAxis: {
    min: 0,
    lineColor: axis_color,
    gridLineColor: grid_color,
    gridLineWidth: 1,
    lineWidth: 1,
    tickWidth: 1,
    title: {
      text: 'Average Travel Time',
      style: {
        color: label_color,
      },
    },
    labels: {
      formatter(this: Highcharts.AxisLabelsFormatterContextObject) {
        const { value } = this;
        const maxValue = this.axis.max;
        if (maxValue > 120 && typeof value === 'number') {
          const mins = value / 60;
          let mantissa = 0;
          if (mins < 10) {
            mantissa = 1;
          }
          return `${mins.toFixed(mantissa)} mins`;
        }
        return `${value.toString()}s`;
      },
      style: {
        color: label_color,
      },
    },
  },
  xAxis: {
    ...nrtXAxisOptions,
  },
  series: [
    {
      id: 'typical_tt',
      type: 'spline',
      data: [],
      name: 'Typical Travel Time',
      color: typical_color,
      lineWidth: 2,
      marker: { enabled: false, symbol: 'circle' },
    },
    {
      id: 'segment_tt',
      type: 'spline',
      data: [],
      name: 'Segment Travel Time',
      color: recent_speed_color,
      lineWidth: 2,
      marker: { enabled: false, symbol: 'circle' },
    },
  ],
};
