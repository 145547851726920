import React from 'react';

import { useSelector } from 'react-redux';

import styles from './Workspace.module.css';
import { MaximizeButton } from './MaximizeButton';
import { workflowStyles } from './workspaceUtils';
import { selectMaximize, selectSegmentId } from '../../state/workflowSlice';

export function BaseNoSegmentSelectedPanel({
  children,
}: React.PropsWithChildren) {
  const maximize = useSelector(selectMaximize);
  const { data_viz_style, header_style } = workflowStyles(maximize);

  return (
    <div className={data_viz_style}>
      <div className={header_style}>
        <div className={styles.chart_header_right}>
          <MaximizeButton />
        </div>
      </div>
      <div className={styles.no_segment_message}>{children}</div>
    </div>
  );
}

export function NoSegmentSelectedPanel() {
  const segmentId = useSelector(selectSegmentId);
  if (segmentId === undefined) {
    return (
      <BaseNoSegmentSelectedPanel>
        <p>Please select a segment to see the chart</p>
      </BaseNoSegmentSelectedPanel>
    );
  } else {
    return undefined;
  }
}
