import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormLabel from '@mui/material/FormLabel';

import {
  kDefaultMapStyle,
  selectMapStyle,
  setMapStyle,
} from 'state/workflowSlice';

import { MapStyle } from '../../appConstants';

import styles from './Map.module.css';

export function MapSettings({
  open,
  handleVisSwitch,
}: {
  open: boolean;
  handleVisSwitch: (boolean) => void;
}) {
  const dispatch = useDispatch();
  const mapStyle = useSelector(selectMapStyle);
  if (open) {
    return (
      <Dialog open={open} onClose={() => handleVisSwitch(false)}>
        <DialogTitle>Map Settings</DialogTitle>
        <DialogContent>
          <FormLabel id="map_style_label">Base map style:</FormLabel>
          <RadioGroup
            value={mapStyle || kDefaultMapStyle}
            aria-labelledby="map_style_label"
            onChange={(event, value) => {
              dispatch(setMapStyle(value));
              handleVisSwitch(false);
            }}
          >
            <FormControlLabel
              value={MapStyle.Dark}
              control={<Radio />}
              label="Dark"
            />
            <FormControlLabel
              value={MapStyle.Sat}
              control={<Radio />}
              label="Satellite"
            />
          </RadioGroup>
        </DialogContent>
      </Dialog>
    );
  } else {
    return null;
  }
}
