import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Tab, Tabs } from '@mui/material';
import { TaskBarGroup } from 'features/task_bar/TaskBar';
import { TaskBarTabPanel } from 'features/task_bar/TaskBarTabs';
import {
  selectProject,
  selectWorkflowTravelTimeToolbarUIMode,
  setWorkflowTravelTimeToolbarUIMode,
} from 'state/workflowSlice';
import { BookmarkToolbar } from './BookmarkToolbar';
import { SavedRoutesToolbar } from './SavedRoutesToolbar';
import styles from '../task_bar/TaskBarTabs.module.css';

export enum WorkflowTravelTimeToolbarTab {
  SavedRoutes = 'saved_routes',
  Locations = 'locations',
}

export function WorkflowTravelTimeToolbar() {
  const dispatch = useDispatch();
  const uiMode = useSelector(selectWorkflowTravelTimeToolbarUIMode);
  const project_slug = useSelector(selectProject);

  const TABS = Object.values(WorkflowTravelTimeToolbarTab);
  const setTab = (index: number) => {
    dispatch(
      setWorkflowTravelTimeToolbarUIMode(
        TABS[index] || WorkflowTravelTimeToolbarTab.SavedRoutes,
      ),
    );
  };

  useEffect(() => {
    dispatch(
      setWorkflowTravelTimeToolbarUIMode(
        uiMode || WorkflowTravelTimeToolbarTab.SavedRoutes,
      ),
    );
  }, [uiMode]);

  return (
    <>
      <Tabs
        value={TABS.indexOf(uiMode || WorkflowTravelTimeToolbarTab.SavedRoutes)}
        onChange={(ev, index) => setTab(index)}
        className={styles.taskbar_top_tabs}
      >
        <Tab label="Saved Routes" />
        <Tab label="Locations" />
      </Tabs>
      <TaskBarTabPanel
        value={uiMode}
        index={WorkflowTravelTimeToolbarTab.SavedRoutes}
      >
        <SavedRoutesToolbar />
      </TaskBarTabPanel>
      <TaskBarTabPanel
        value={uiMode}
        index={WorkflowTravelTimeToolbarTab.Locations}
      >
        <BookmarkToolbar showTitle={false} />
      </TaskBarTabPanel>
    </>
  );
}
