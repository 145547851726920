import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectProject,
  selectRouteSelection,
  selectSegmentId,
  setRouteSelection,
  setSelectedSegmentId,
} from '../../state/workflowSlice';
import { LayerContext } from '../../state/LayerContext';
import { useGetSegmentTranslateQuery } from '../../state/apiSlice';

export function split_segmentId(segmentId: string): {
  segmentSub: string;
  layer_id: number;
} {
  const segmentIdString = String(segmentId);
  const bits = segmentIdString.split('x');
  if (bits.length === 2) {
    return {
      segmentSub: bits[1],
      layer_id: parseInt(bits[0], 10),
    };
  } else {
    return {
      segmentSub: bits[0],
      layer_id: undefined,
    };
  }
}

export function needsCrosswalk(layer, segmentId, routeSelection) {
  let doSegmentTranslate = false;
  if (segmentId) {
    if (split_segmentId(segmentId).layer_id !== layer) {
      doSegmentTranslate = true;
    }
  }
  if (routeSelection) {
    for (let i = 0; i < routeSelection.length; i++) {
      if (split_segmentId(routeSelection[i]).layer_id !== layer) {
        doSegmentTranslate = true;
      }
    }
  }
  return doSegmentTranslate;
}

export function useSegmentSelectionCrosswalk() {
  const project_slug = useSelector(selectProject);
  const segmentId = useSelector(selectSegmentId);
  const routeSelection = useSelector(selectRouteSelection);
  const { layer } = useContext(LayerContext);
  const dispatch = useDispatch();

  // If segmentId implies a different layer, request the equivalent
  // segment for the current layer

  const doSegmentTranslate = needsCrosswalk(layer, segmentId, routeSelection);

  let segmentOrRouteIds;
  if (routeSelection) {
    segmentOrRouteIds = routeSelection;
  } else if (segmentId) {
    segmentOrRouteIds = [segmentId];
  }

  const { currentData: newSegmentIds } = useGetSegmentTranslateQuery(
    { routeIds: segmentOrRouteIds, layerId: layer },
    { skip: !segmentOrRouteIds || !doSegmentTranslate || !layer },
  ) as any;
  if (newSegmentIds) {
    if (routeSelection) {
      dispatch(setRouteSelection(newSegmentIds));
      dispatch(setSelectedSegmentId(newSegmentIds[newSegmentIds.length - 1]));
    } else {
      console.log(
        `Setting translated segment selection to ${newSegmentIds[0]}`,
      );
      dispatch(setSelectedSegmentId(newSegmentIds[0]));
    }
    return newSegmentIds[0];
  }
  return undefined;
}
