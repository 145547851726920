import React, { PropsWithChildren } from 'react';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import styles from './TaskBarTabs.module.css';

export function TaskBarTabPanel({
  children,
  value,
  index,
}: {
  value: string;
  index: string;
} & PropsWithChildren) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={styles.task_bar_tab}
    >
      {value === index && children}
    </div>
  );
}
