import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';

import { useTheme } from '@mui/material/styles';

import {
  setSlowdownThreshold,
  selectSlowdownThreshold,
} from 'state/workflowSlice';

import styles from 'features/task_bar/Taskbar.module.css';
import { InfoHover } from '../info_hover/InfoHover';

const thresholdStep = 5;
const thresholdMin = 0;
const thresholdMax = 100;

const defaultSliderValue = 65;
const Input = styled(MuiInput)`
  width: 42px;
`;

export function SlowdownThresholdSlider({ tooltip }: { tooltip?: string }) {
  const threshold = useSelector(selectSlowdownThreshold);
  const dispatch = useDispatch();
  const theme = useTheme();

  function setThreshold(newValue: number) {
    dispatch(setSlowdownThreshold(newValue / 100));
  }
  const handleSliderChange = (event: Event, newValue: number) => {
    console.assert(newValue >= thresholdMin && newValue <= thresholdMax);
    setThreshold(newValue);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue =
      event.target.value === ''
        ? defaultSliderValue
        : Number(event.target.value);
    setThreshold(newValue);
  };

  const handleBlur = () => {
    if (threshold < thresholdMin) {
      setThreshold(thresholdMin);
    } else if (threshold > thresholdMax) {
      setThreshold(thresholdMax);
    }
  };

  return (
    <Box className={styles.threshold_slider}>
      <Typography id="threshold-slider" gutterBottom>
        Slowdown Threshold:
        <InfoHover>{tooltip}</InfoHover>
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Slider
            value={typeof threshold === 'number' ? threshold * 100 : 0}
            onChange={handleSliderChange}
            aria-labelledby="threshold-slider"
            min={thresholdMin}
            max={thresholdMax}
            step={thresholdStep}
            color="primary"
          />
        </Grid>
        <Grid item>
          <Input
            sx={{
              width: '4rem',
            }}
            color="primary"
            value={threshold * 100}
            size="small"
            onChange={handleInputChange}
            onBlur={handleBlur}
            endAdornment={
              <InputAdornment
                position="end"
                className={styles.percent_adornment}
              >
                %
              </InputAdornment>
            }
            inputProps={{
              step: thresholdStep,
              min: thresholdMin,
              max: thresholdMax,
              type: 'number',
              'aria-labelledby': 'threshold-slider',
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
