import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Add,
  Check,
  Close,
  Delete,
  Edit,
  Search,
  Star,
  StarBorder,
} from '@mui/icons-material';
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import TextField from '@mui/material/TextField';

import {
  Bookmark,
  selectDisplayedBookmark,
  selectInLocationEditMode,
  setDisplayedBookmark,
  setInLocationEditMode,
  useBookmarkCreateMutation,
  useBookmarkDeleteMutation,
  useBookmarkUpdateMutation,
  useGetBookmarksQuery,
} from 'state/bookmarkSlices';
import { updateNameError } from './util';

import {
  selectMapZoom,
  selectProject,
  setMapCenter,
  setMapZoom,
} from '../../state/workflowSlice';
import { jsonPointToLatLng, latLngToJsonPoint } from '../map/mapUtils';
import styles from './BookmarkToolbar.module.css';

export function BookmarkToolbar({ showTitle = true }: { showTitle?: boolean }) {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [editBookmarkName, setEditBookmarkName] = useState('');
  const [editBookmarkId, setEditBookmarkId] = useState(undefined);
  const [deleteBookmarkId, setDeleteBookmarkId] = useState(undefined);
  const project_slug = useSelector(selectProject);
  const displayedBookmark = useSelector(selectDisplayedBookmark);
  const inLocationEditMode = useSelector(selectInLocationEditMode);
  const mapZoom = useSelector(selectMapZoom);

  const { currentData: bookmarkData } = useGetBookmarksQuery(project_slug, {
    skip: !project_slug,
  });
  const [updateBookmark, updateBookmarkStatus] = useBookmarkUpdateMutation();
  const [createBookmark, createBookmarkStatus] = useBookmarkCreateMutation();
  const [deleteBookmark, deleteBookmarkStatus] = useBookmarkDeleteMutation();

  let bookmarks = bookmarkData?.bookmarks ? [...bookmarkData.bookmarks] : [];
  bookmarks = bookmarks.sort((a, b) => +b.starred - +a.starred);
  if (search && search !== '' && showSearch) {
    bookmarks = bookmarks.filter((value) =>
      value.name.toLowerCase().includes(search.toLowerCase()),
    );
  }

  function exitEditMode() {
    dispatch(setInLocationEditMode(false));
    setEditBookmarkId(undefined);
    setEditBookmarkName('');
    dispatch(setDisplayedBookmark(undefined));
  }

  const onBookmarkSelect = (event, index) => {
    exitEditMode();
    dispatch(setMapCenter(jsonPointToLatLng(bookmarks[index].center)));
    if (bookmarks[index].zoom) {
      dispatch(setMapZoom(bookmarks[index].zoom));
    }
  };

  const onBookmarkStarClick = (event, index) => {
    const newBookmark = { ...bookmarks[index] };
    newBookmark.starred = !newBookmark.starred;
    updateBookmark({
      project_slug: project_slug as string,
      bookmark_id: newBookmark.id,
      bookmark: newBookmark,
    });
  };

  const onBookmarkEditClick = (event, index) => {
    const bookmark = bookmarks[index];
    dispatch(setDisplayedBookmark(jsonPointToLatLng(bookmark.center)));
    dispatch(setMapCenter(jsonPointToLatLng(bookmark.center)));
    if (bookmark.zoom) {
      dispatch(setMapZoom(bookmark.zoom));
    }
    dispatch(setInLocationEditMode(true));
    setEditBookmarkName(bookmark.name);
    setEditBookmarkId(bookmark.id);
  };

  const onBookmarkSubmitClick = (event, index) => {
    const bookmark = bookmarks[index];
    console.assert(bookmark.id === editBookmarkId);
    updateBookmark({
      project_slug: project_slug as string,
      bookmark_id: editBookmarkId,
      bookmark: {
        id: editBookmarkId,
        name: editBookmarkName,
        center: latLngToJsonPoint(displayedBookmark),
        zoom: mapZoom,
        starred: true,
      },
    });
    exitEditMode();
  };

  const onAddSubmit = (event) => {
    dispatch(setInLocationEditMode(false));
    createBookmark({
      project_slug: project_slug as string,
      bookmark_id: editBookmarkId,
      bookmark: {
        id: editBookmarkId,
        name: editBookmarkName,
        center: latLngToJsonPoint(displayedBookmark),
        zoom: mapZoom,
        starred: true,
      },
    });
    exitEditMode();
  };

  const onBookmarkDeleteConfirmClick = (event, index) => {
    const bookmark = bookmarks[index];
    console.assert(
      bookmark.id === deleteBookmarkId,
      deleteBookmarkId,
      bookmark.id,
      bookmark,
    );
    deleteBookmark({
      project_slug: project_slug as string,
      bookmark_id: deleteBookmarkId,
    });
    setDeleteBookmarkId(undefined);
  };

  const onAddActivate = (event) => {
    dispatch(setDisplayedBookmark(undefined));
    dispatch(setInLocationEditMode(true));
    setEditBookmarkName('');
    setEditBookmarkId(-1);
  };

  function renderBookmark(bookmark: Bookmark, index) {
    const { starred, name, id } = bookmark;
    const isEditing = editBookmarkId === id && inLocationEditMode;
    const isDeleting = deleteBookmarkId === id;
    let secondaryActions;
    let itemContent;

    if (!isEditing && !isDeleting) {
      // Normal bookmark view
      secondaryActions = (
        <>
          <IconButton
            className={styles.bookmark_edit_button}
            edge="end"
            aria-label="edit"
            title="Edit"
            onClick={(event) => onBookmarkEditClick(event, index)}
          >
            <Edit />
          </IconButton>
          <IconButton
            title="Star"
            onClick={(event) => onBookmarkStarClick(event, index)}
          >
            {(starred && <Star />) || <StarBorder />}
          </IconButton>
          <IconButton
            title="Delete"
            className={styles.bookmark_delete_button}
            onClick={(event) => {
              setDeleteBookmarkId(id);
              exitEditMode();
            }}
          >
            <Delete />
          </IconButton>
        </>
      );
      itemContent = (
        <ListItemButton
          className={styles.bookmark_item_button}
          onClick={(event) => onBookmarkSelect(event, index)}
        >
          <ListItemText primary={name} />
        </ListItemButton>
      );
    } else if (isDeleting) {
      secondaryActions = (
        <>
          <IconButton
            edge="end"
            aria-label="delete"
            title="Delete"
            color="error"
            onClick={(event) => onBookmarkDeleteConfirmClick(event, index)}
          >
            <Check />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="cancel"
            title="Cancel"
            color="success"
            onClick={(event) => setDeleteBookmarkId(undefined)}
          >
            <Close />
          </IconButton>
        </>
      );
      itemContent = (
        <ListItemText
          className={styles.confirm_delete_field}
          primary={`Delete '${name}'?`}
        />
      );
    } else {
      const editErrorString = updateNameError(
        name,
        editBookmarkName,
        bookmarks.map((e) => e.name),
      );
      // Editing view
      secondaryActions = (
        <>
          <IconButton
            edge="end"
            aria-label="submit edit"
            title="Submit edit"
            onClick={(event) => onBookmarkSubmitClick(event, index)}
            disabled={!!editErrorString}
          >
            <Check />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="cancel"
            title="Cancel"
            onClick={(event) => exitEditMode()}
          >
            <Close />
          </IconButton>
        </>
      );
      itemContent = (
        <TextField
          label="Name"
          value={editBookmarkName}
          onChange={(event) => setEditBookmarkName(event.target.value)}
          variant="standard"
          size="small"
          className={styles.edit_name_field}
          error={!!editErrorString}
          helperText={editErrorString}
        />
      );
    }

    return (
      <ListItem
        disablePadding
        secondaryAction={secondaryActions}
        selected={id === editBookmarkId}
        key={id}
      >
        {itemContent}
      </ListItem>
    );
  }

  return (
    <>
      <div className={styles.header_padded}>
        {!showSearch && (
          <>
            {showTitle && 'Locations'}
            <IconButton
              onClick={onAddActivate}
              size="small"
              title="Add location"
            >
              <Add />
            </IconButton>
            <IconButton
              onClick={(event) => setShowSearch(true)}
              size="small"
              title="Search locations"
            >
              <Search />
            </IconButton>
          </>
        )}
        {showSearch && (
          <>
            <TextField
              label="Location name"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              variant="standard"
              size="small"
              className={styles.search_text_field}
            />
            <IconButton
              onClick={(event) => setShowSearch(false)}
              size="small"
              title="Hide search"
            >
              <Close />
            </IconButton>
          </>
        )}
      </div>
      {inLocationEditMode && editBookmarkId === -1 && (
        <div className={styles.header_padded}>
          <TextField
            label="New location name"
            value={editBookmarkName}
            onChange={(event) => setEditBookmarkName(event.target.value)}
            variant="standard"
            size="small"
            className={styles.search_text_field}
            error={
              !!updateNameError(
                undefined,
                editBookmarkName,
                bookmarks.map((e) => e.name),
              )
            }
            helperText={updateNameError(
              undefined,
              editBookmarkName,
              bookmarks.map((e) => e.name),
            )}
          />
          <IconButton
            onClick={onAddSubmit}
            disabled={
              !displayedBookmark ||
              !!updateNameError(
                undefined,
                editBookmarkName,
                bookmarks.map((e) => e.name),
              )
            }
            size="small"
          >
            <Check />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="cancel"
            title="Cancel"
            onClick={(event) => {
              dispatch(setInLocationEditMode(false));
            }}
          >
            <Close />
          </IconButton>
        </div>
      )}
      {bookmarkData?.bookmarks.length !== 0 ? (
        <List className={styles.bookmark_toolbar_list}>
          {bookmarks && bookmarks.map(renderBookmark)}
        </List>
      ) : (
        <div>
          <p className={styles.no_bookmarks_message}>No locations found</p>
          <p className={styles.no_bookmarks_message}>Press + to add</p>
        </div>
      )}
    </>
  );
}
