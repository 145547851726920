import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import {
  BE_BIN_15_MINS,
  BE_BIN_60_MINS,
  BIN_15_MINS,
  BIN_60_MINS,
} from 'appConstants';

import {
  setBinSize,
  selectBinSize,
  selectCurrentProjectInfo,
} from 'state/workflowSlice';

import styles from 'features/task_bar/Taskbar.module.css';

const kDefaultBinSize = BIN_60_MINS;

export function mapBinSizeBeToFe(be_rep) {
  if (be_rep === BE_BIN_15_MINS) {
    return BIN_15_MINS;
  } else if (be_rep === BE_BIN_60_MINS) {
    return BIN_60_MINS;
  }
  return undefined;
}

function displayBinSize(binSize: string) {
  if (binSize === BIN_15_MINS) {
    return '15 Minutes';
  } else if (binSize === BIN_60_MINS) {
    return '1 Hour';
  } else return null;
}

export function BinSizePicker() {
  const binSize = useSelector(selectBinSize);
  const userProject = useSelector(selectCurrentProjectInfo);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    const newBinSize = event.target.value;
    // console.log(`BinSizePicker handleChange newBinSize: ${newBinSize}`);
    dispatch(setBinSize(newBinSize));
  };

  useEffect(() => {
    if (!binSize && userProject) {
      // console.log(`BinSizePicker setting empty units to ${kDefaultUnits}`);
      if (userProject.available_bins.length === 1) {
        dispatch(setBinSize(mapBinSizeBeToFe(userProject.available_bins[0])));
      } else {
        dispatch(setBinSize(kDefaultBinSize));
      }
    }
  }, [dispatch, binSize, userProject]);
  if (userProject) {
    if (userProject.available_bins.length === 1) {
      return null;
    } else {
      return (
        <div className={styles.task_bar_component}>
          <InputLabel id="bin-size-picker-label">
            Bins:
            <Select
              className={styles.task_bar_select_wide}
              value={binSize || ''}
              onChange={handleChange}
              labelId="bin-size-picker-label"
              size="small"
            >
              <MenuItem key={BIN_15_MINS} value={BIN_15_MINS}>
                {displayBinSize(BIN_15_MINS)}
              </MenuItem>
              <MenuItem key={BIN_60_MINS} value={BIN_60_MINS}>
                {displayBinSize(BIN_60_MINS)}
              </MenuItem>
            </Select>
          </InputLabel>
        </div>
      );
    }
  } else {
    return null;
  }
}
