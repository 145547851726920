import React, { PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { dispatchUserStatus } from '../../app/AppDispatcher';
import { AppHeader } from '../app_header/AppHeader';
import { selectUserStatus } from '../../state/userSlice';

export function UserViewBase({ children }: PropsWithChildren) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userStatus = useSelector(selectUserStatus);
  // console.log(`Login userStatus: ${userStatus}`);

  useEffect(() => {
    // Check if user is already loggedIn
    dispatchUserStatus(userStatus, dispatch, navigate);
  }, [userStatus]);

  return (
    <div className="App">
      <AppHeader />
      {children}
    </div>
  );
}
