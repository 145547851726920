import { PlanningData, XyArray } from 'appTypes';

import { BIN_15_MINS, BIN_60_MINS } from 'appConstants';

export const DAY_WEEKDAY = 'Weekday';
export const DAY_SATURDAY = 'Saturday';
export const DAY_SUNDAY = 'Sunday';
export const MINUTES_PER_DAY = 24 * 60;

export function volume_data_map(row) {
  return { day: row[0], bin: row[1], y: row[2] };
}

export function bin_size_to_minutes(binSize) {
  console.assert([BIN_15_MINS, BIN_60_MINS].includes(binSize));
  return binSize === BIN_15_MINS ? 15 : 60;
}

export class PlanningChartDataExpander {
  binSize: number;
  firstBin: number;
  lastBin: number;
  numBins: number;

  constructor(binSize: string) {
    this.binSize = bin_size_to_minutes(binSize);
    this.firstBin = Date.UTC(1970, 0, 1);
    this.lastBin = Date.UTC(1970, 0, 4);
    this.numBins = (MINUTES_PER_DAY * 3) / this.binSize + 1;
    // console.log(`numBins ${this.numBins} firstBin ${this.firstBin} lastBin ${this.lastBin}`);
  }

  dayNum(day: string): number {
    return [DAY_WEEKDAY, DAY_SATURDAY, DAY_SUNDAY].indexOf(day);
  }

  toIndex(day: string, bin: number) {
    const fix_bin = Math.floor(bin / 100) * 60 + (bin % 100);
    const minutes = this.dayNum(day) * MINUTES_PER_DAY + fix_bin;
    const index = minutes / this.binSize;
    return index;
  }

  expandPlanningData(rawData: PlanningData): XyArray {
    // extract and backfill sparse SpeedData array with null datapoints for all time bins

    const binTicks = this.binSize * 60 * 1000;

    const data = Array(this.numBins);
    for (let i = 0; i < this.numBins; i += 1) {
      const b = this.firstBin + i * binTicks;
      data[i] = { x: b, y: null };
    }
    // console.log(`expandSpeeds empty speeds: ${JSON.stringify(speeds.slice(0, 10))}`);

    if (rawData) {
      const sparseData = rawData.data.map(volume_data_map);
      for (let i = 0; i < sparseData.length; i += 1) {
        const { day, bin, y } = sparseData[i];
        const index = this.toIndex(day, bin);
        // console.assert(index % 1 === 0);
        // console.log(`index: ${index} day: ${day}, bin: ${bin}, y: ${y}`);
        data[index].y = y * (60.0 / this.binSize);
      }
    }
    // console.log(`expandPlanningData data: ${JSON.stringify(data.slice(0, 10))}`);

    return data;
  }

  volumeThresholdBands(
    data: XyArray,
    threshold: number,
    thresholdColor: string,
  ) {
    // return plotBand array with a band for each interval where data is below threshold
    const plotBands = Array(0);
    const binTicks = this.binSize * 60 * 1000;

    if (data && threshold) {
      let fromX = null;
      let toX = null;
      for (let i = 0; i < data.length; i += 1) {
        const { x, y } = data[i];
        const isBelow: boolean = y && y < threshold;

        if (isBelow) {
          if (fromX === null) {
            // entering band
            fromX = x;
            // console.log(`enter band at ${x}`);
          }
          toX = x;
        } else {
          if (fromX !== null) {
            // leaving band
            const raw_fromX = fromX;
            const raw_toX = toX;
            if (fromX > this.firstBin) {
              // Don't do this if the band starts at the very begining
              fromX -= binTicks / 2; // expand band to bin midpoint before and after
            }
            if (i < data.length - 1) {
              // Don't do this if the band ends right at the end
              toX += binTicks / 2;
            } else {
              toX += binTicks;
            }
            plotBands.push({
              color: thresholdColor,
              opacity: 0.5,
              from: fromX,
              raw_from: raw_fromX,
              to: toX,
              raw_to: raw_toX,
            });
            // console.log(`leaving band at ${x}`);
          }
          fromX = null;
          toX = null;
        }
      }

      if (fromX) {
        fromX -= binTicks / 2;
        plotBands.push({ color: thresholdColor, from: fromX, to: toX });
      }
    }
    return plotBands;
  }

  flatline(y: number): XyArray {
    // return 2-element flatline array with datapoints at first and last time bin
    const bins = [];
    bins.push({ x: this.firstBin, y });
    bins.push({ x: this.lastBin, y });
    return bins;
  }
}
