import React from 'react';
import styles from './Spinner.module.css';

export default function LoadingSpinner(props: { large?: boolean }) {
  const { large } = props;
  return (
    <div
      className={
        large ? styles.loading_container_large : styles.loading_container
      }
    >
      <div
        className={
          large ? styles.loading_spinner_large : styles.loading_spinner
        }
      />
    </div>
  );
}

export function FloatingDiv({
  children,
  round = false,
  onClick = undefined,
}: {
  round?: boolean;
  onClick?: () => void;
} & React.PropsWithChildren) {
  const classname = round
    ? styles.loading_container_floating_round
    : styles.loading_container_floating;
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={classname}
      style={{ pointerEvents: onClick ? 'unset' : 'none' }}
      onClick={onClick}
      onKeyDown={(e) => {
        if (onClick && (e.key === 'Enter' || e.key === ' ')) {
          e.preventDefault();
          onClick();
        }
      }}
      role={onClick ? 'button' : undefined}
    >
      {children}
    </div>
  );
}

export function FloatingLoadingSpinner() {
  return (
    <FloatingDiv round>
      <div className={styles.loading_spinner_floating} />
    </FloatingDiv>
  );
}

export function BottomLeftFloatingLoadingSpinner() {
  return <div className={styles.loading_spinner_floating_small} />;
}
