import styles from 'features/workspace/Workspace.module.css';

import { MAXIMIZE_MAP, MAXIMIZE_DATA } from 'appConstants';

export function workflowStyles(maximize: string) {
  // console.assert([MAXIMIZE_MAP, MAXIMIZE_DATA, null].includes(maximize), `maximize: ${maximize}`);

  let map_style = styles.map_container;
  let data_viz_style = styles.chart_container;
  let header_style = styles.chart_header_wrapper;
  if (maximize === MAXIMIZE_MAP) {
    map_style = styles.map_container_max;
    data_viz_style = styles.chart_container_min;
    header_style = styles.chart_header_min;
  } else if (maximize === MAXIMIZE_DATA) {
    map_style = styles.map_container_min;
    data_viz_style = styles.chart_container_max;
  }
  return { map_style, data_viz_style, header_style };
}
