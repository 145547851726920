import React from 'react';

import { NRTDatePicker } from 'features/task_bar/DatePicker';
import { TypicalMonthPicker } from 'features/task_bar/TypicalMonthPicker';
import { SlowdownThresholdSlider } from 'features/task_bar/SlowdownThresholdSlider';
import { TypicalMonthAvailabilityWarning } from '../task_bar/TypicalMonthAvailabilityWarning';
import { ComparisonPicker } from '../task_bar/ComparisonPicker';

export function ChartSettings({
  slowdownTooltip,
}: {
  slowdownTooltip?: string;
}) {
  return (
    <div>
      <NRTDatePicker />
      <ComparisonPicker />
      <TypicalMonthAvailabilityWarning />
      <SlowdownThresholdSlider tooltip={slowdownTooltip} />
    </div>
  );
}
