import {
  COLORMAP_MINUS_1_GREY,
  COLORMAP_TRAFFICLIGHT_0_1,
  COLORMAP_TRAFFICLIGHT_100_0,
  COLORMAP_VOLUME_M1_1,
  viridis,
} from '../chart/colorMaps';

export const TL_METRIC_ABS_SPEED = 'speed_abs';
export const TL_METRIC_FF_REL_SPEED = 'speed_ff';
export const TL_METRIC_ABS_VOL = 'count_abs';
export const TL_METRIC_TYP_REL_SPEED = 'speed_typ';
export const TL_METRIC_TYP_REL_VOL = 'count_typ';
export const TL_METRIC_TYP_REL_SPEED_VOL = 'count_speed_typ';
export const TL_METRIC_FF_REL_SPEED_VOL = 'count_speed_ff';
export const TL_METRIC_FF_VHD = 'vhd';
export const TL_METRIC_TYP_VHD = 'typ_vhd';
export const TL_METRIC_TYP_RT = 'speed_typ_rt';
export const TL_METRIC_FF_RT = 'speed_ff_rt';
export const TL_METRIC_TYP_VNRT = 'speed_typ_vnrt';
export const TL_METRIC_FF_VNRT = 'speed_ff_vnrt';

export const METRIC_PLACEHOLDER = 'metric';

export const TL_METRIC_OPTIONS_WITH_TYPICAL = [
  TL_METRIC_TYP_REL_SPEED,
  TL_METRIC_TYP_REL_VOL,
  TL_METRIC_TYP_REL_SPEED_VOL,
  TL_METRIC_TYP_VHD,
  TL_METRIC_TYP_RT,
];

export const TL_METRICS_OPTIONS_NRT = [
  TL_METRIC_TYP_REL_SPEED_VOL,
  TL_METRIC_FF_REL_SPEED_VOL,
  TL_METRIC_TYP_REL_VOL,
  TL_METRIC_TYP_VHD,
];

export const TL_METRICS_OPTIONS_VNRT = [
  TL_METRIC_TYP_RT,
  TL_METRIC_FF_RT,
  TL_METRIC_TYP_VNRT,
  TL_METRIC_FF_VNRT,
];

export const TL_METRICS_OPTIONS_VNRT_EQUIVALENT = {
  [TL_METRIC_FF_RT]: TL_METRIC_FF_VNRT,
  [TL_METRIC_TYP_RT]: TL_METRIC_TYP_VNRT,
};

export const TL_METRICS_OPTIONS_RT_EQUIVALENT = {
  [TL_METRIC_FF_VNRT]: TL_METRIC_FF_RT,
  [TL_METRIC_TYP_VNRT]: TL_METRIC_TYP_RT,
};

export function isRealtimeMetric(metric) {
  return TL_METRICS_OPTIONS_VNRT_EQUIVALENT[metric] !== undefined;
}

export function isVNRTMetric(metric) {
  return TL_METRICS_OPTIONS_RT_EQUIVALENT[metric] !== undefined;
}

const speeds = (timeOfDayOffset) => ['get', `s${timeOfDayOffset}`];
const typicalSpeeds = (timeOfDayOffset) => ['get', `ts${timeOfDayOffset}`];
const rtSpeed = (timeOfDayOffset) => [
  'coalesce',
  ['feature-state', 'rtspeed'],
  -1,
];
const vnrtSpeed = (timeOfDayOffset) => [
  'coalesce',
  [
    'coalesce',
    ['feature-state', `s${timeOfDayOffset}`],
    speeds(timeOfDayOffset),
  ],
  -1,
];
const vnrtFFSpeeds = (timeOfDayOffset) => ['get', 'vnrt_freeflow_kph'];
const fallbackdTypicalSpeeds = (timeOfDayOffset) => [
  'coalesce',
  ['get', `ts${timeOfDayOffset}`],
  vnrtFFSpeeds(timeOfDayOffset),
];
const counts = (timeOfDayOffset) => ['get', `c${timeOfDayOffset}`];
const typicalCounts = (timeOfDayOffset) => ['get', `tc${timeOfDayOffset}`];

export function metricExprDeepSub(expr: Array<any>, sub) {
  // Substitutes METRIC_PLACEHOLDER for the passed sub
  for (let i = 0; i < expr.length; i++) {
    if (expr[i] === METRIC_PLACEHOLDER) {
      expr[i] = sub;
    } else if (Array.isArray(expr[i])) {
      expr[i] = metricExprDeepSub(expr[i], sub);
    }
  }
  return expr;
}

const ff_relative_speed_expr = ({ timeOfDayOffset }) => [
  '/',
  speeds(timeOfDayOffset),
  ['get', 'freeflow_speed_kph'],
];
const typ_relative_speed_expr = ({ timeOfDayOffset }) => [
  '/',
  speeds(timeOfDayOffset),
  typicalSpeeds(timeOfDayOffset),
];

const typ_relative_count_expr = ({ timeOfDayOffset }) => [
  'interpolate',
  ['linear'],
  [
    '*',
    [
      '-',
      ['/', counts(timeOfDayOffset), ['+', typicalCounts(timeOfDayOffset), 1]],
      1,
    ],
    [
      'interpolate',
      ['linear'],
      ['max', typicalCounts(timeOfDayOffset), counts(timeOfDayOffset)],
      0,
      -0.1,
      50,
      -1,
    ],
  ],
  -5,
  -2,
  -1,
  -1,
  1,
  1,
];

export const counts_absolute_size_expr = (
  timeOfDayOffset,
  hourlyMaxCount,
  base,
) => [
  'interpolate',
  ['linear'],
  [
    '/',
    [
      '*',
      ['max', counts(timeOfDayOffset), typicalCounts(timeOfDayOffset)],
      100,
    ],
    1000,
  ],
  0,
  0.5 * base,
  10,
  1.5 * base,
  100,
  3.0 * base,
];

const legend_stops_0_1_perc = [
  {
    value: 0,
    label: ['Stopped', '0%'],
  },
  {
    value: 1,
    label: ['Typical', '100%'],
  },
];

const legend_stops_0_1_ff = [
  {
    value: 0,
    label: ['Stopped', '0%'],
  },
  {
    value: 1,
    label: ['Freeflow', '100%'],
  },
];

const legend_stops_1_m1_perc_inverted = [
  {
    value: -2,
    label: ['(Higher than normal)', '500%'],
  },
  {
    value: -1,
    label: ['', '100%'],
  },
  {
    value: 0,
    label: ['', 'Typical'],
  },
  {
    value: 1,
    label: ['(Lower than normal)', '-100%'],
  },
];

const legend_stops_100_0_congestion = [
  {
    value: 0,
    label: 'Congested',
  },
  {
    value: 100,
    label: 'Freeflow',
  },
];

export const TL_METRIC_OPTIONS = {
  [TL_METRIC_FF_REL_SPEED_VOL]: {
    metric_expr: ff_relative_speed_expr,
    colors: COLORMAP_TRAFFICLIGHT_0_1,
    legend_stops: legend_stops_0_1_ff,
    // legend_title: '% relative speed',
    width: 'count_absolute',
    shown: ['<', METRIC_PLACEHOLDER, 0.99],
  },
  [TL_METRIC_TYP_REL_SPEED_VOL]: {
    metric_expr: typ_relative_speed_expr,
    colors: COLORMAP_TRAFFICLIGHT_0_1,
    legend_stops: legend_stops_0_1_perc,
    // legend_title: '% relative speed',
    width: 'count_absolute',
    shown: ['<', METRIC_PLACEHOLDER, 0.99],
  },
  [TL_METRIC_TYP_REL_VOL]: {
    metric_expr: typ_relative_count_expr,
    colors: COLORMAP_VOLUME_M1_1,
    legend_stops: legend_stops_1_m1_perc_inverted,
    // legend_title: '% relative count',
    width: 'count_absolute',
    shown: ['>', ['abs', METRIC_PLACEHOLDER], 0.01],
  },
  [TL_METRIC_TYP_VHD]: {
    metric_expr: ({ timeOfDayOffset, hourlyMaxCount }) => [
      '-',
      100,
      [
        '*',
        [
          '*',
          ['max', ['-', 1, typ_relative_speed_expr({ timeOfDayOffset })], 0], // 0 - 1 congestion
          ['/', ['*', counts(timeOfDayOffset), 100], hourlyMaxCount], // 0 - 100% of max traffic
        ],
        7, // Its never all of the cars at standstill so cut off the range at 15% VHD
      ],
    ],
    colors: COLORMAP_TRAFFICLIGHT_100_0,
    legend_stops: legend_stops_100_0_congestion,
    legend_title: 'Trip weighted congestion',
    shown: ['<', METRIC_PLACEHOLDER, 99],
  },
  [TL_METRIC_TYP_RT]: {
    metric_expr: ({ timeOfDayOffset }) => [
      'interpolate',
      ['linear'],
      ['/', rtSpeed(timeOfDayOffset), fallbackdTypicalSpeeds(timeOfDayOffset)],
      -1,
      1,
      0.001,
      1,
      0.002,
      0,
      1,
      1,
    ], // Compensate for missing speeds data,
    colors: COLORMAP_TRAFFICLIGHT_0_1,
    legend_stops: legend_stops_0_1_perc,
    legend_title: '% relative speed',
  },
  [TL_METRIC_FF_RT]: {
    metric_expr: ({ timeOfDayOffset }) => [
      'interpolate',
      ['linear'],
      ['/', rtSpeed(timeOfDayOffset), vnrtFFSpeeds(timeOfDayOffset)],
      -1,
      1,
      0.001,
      1,
      0.002,
      0,
      1,
      1,
    ], // Compensate for missing speeds data,
    colors: COLORMAP_TRAFFICLIGHT_0_1,
    legend_stops: legend_stops_0_1_ff,
    legend_title: '% relative speed',
  },
  [TL_METRIC_TYP_VNRT]: {
    metric_expr: ({ timeOfDayOffset }) => [
      'interpolate',
      ['linear'],
      [
        '/',
        vnrtSpeed(timeOfDayOffset),
        fallbackdTypicalSpeeds(timeOfDayOffset),
      ],
      -1,
      1,
      -0.001,
      1,
      0,
      0,
      1,
      1,
    ], // Compensate for missing speeds data,
    colors: COLORMAP_TRAFFICLIGHT_0_1,
    legend_stops: legend_stops_0_1_perc,
    legend_title: '% relative speed',
  },
  [TL_METRIC_FF_VNRT]: {
    metric_expr: ({ timeOfDayOffset }) => [
      'interpolate',
      ['linear'],
      ['/', vnrtSpeed(timeOfDayOffset), vnrtFFSpeeds(timeOfDayOffset)],
      -1,
      1,
      -0.001,
      1,
      0,
      0,
      1,
      1,
    ], // Compensate for missing speeds data,
    colors: COLORMAP_TRAFFICLIGHT_0_1,
    legend_stops: legend_stops_0_1_ff,
    legend_title: '% relative speed',
  },
};
